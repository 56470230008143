/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// import Panels from "views/components/Panels.js";

// import Timeline from "views/pages/Timeline.js";


import { lazy, Suspense } from "react";

import React from 'react';
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { Spinner } from "reactstrap";

const Panels = lazy(() =>
  import ("views/components/Panels.js"));
const Timeline = lazy(() =>
  import ("views/pages/Timeline.js"));


const LazyPanels = (props) => {
  return ( <
    Suspense fallback = { <
      div
      className = "content"
      style = {
        {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: '#19191A'
        }
      } >
      <
      Spinner
      style = { { width: "3rem", height: "3rem" } }
      type = "grow"
      size = "lg" /
      >
      <
      /div>
    } >
    <
    ErrorBoundary >
    <
    Panels { ...props }
    /> <
    /ErrorBoundary> <
    /Suspense>
  );
};

const LazyTimeline = (props) => {
  return ( <
    Suspense fallback = { <
      div
      className = "content"
      style = {
        {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: '#19191A'
        }
      } >
      <
      Spinner
      style = { { width: "3rem", height: "3rem" } }
      type = "grow"
      size = "lg" /
      >
      <
      /div>
    } >
    <
    ErrorBoundary >
    <
    Timeline { ...props }
    /> <
    /ErrorBoundary> <
    /Suspense>
  );
};


const routes = [

  {
    path: "/index",
    name: "Index",
    component: LazyPanels,
    layout: "/admin",
    icon: "tim-icons icon-chart-pie-36",
  },

  // {
  //   path: "/timeline",
  //   name: "Timeline",
  //   component: LazyTimeline,
  //   layout: "/admin",
  //   icon: "tim-icons icon-align-center",
  // }

];

export default routes;
